<template>
  <div class="ribbon-wrapper" :style="wrapperStyle">
    <div class="ribbon" :style="ribbonStyle"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: "Ribbon",
  props: {
    fontSize: {
      type: Number,
      default: 15
    },
    wrapWidth: {
      type: Number,
      default: 85
    },
    wrapHeight: {
      type: Number,
      default: 88
    },
    ribbonWidth: {
      type: Number,
      default: 120
    },
    ribbonLeft: {
      type: Number,
      default: -30
    },
    backgroundColor: String,
    fontColor: String,
    alignRight: Boolean,
    big: Boolean
  },
  computed: {
    wrapperStyle() {
      let wrapWidth = this.big ? 100 : this.wrapWidth;
      let wrapHeight = this.big ? 100 : this.wrapHeight;

      let style = `width: ${wrapWidth}px; height: ${wrapHeight}px;`;

      if (this.alignRight) {
        style += "right: -1px;";
      } else {
        style += "left: -1px;";
      }
      return style;
    },
    ribbonStyle() {
      let fontSize = this.big ? 18 : this.fontSize;
      let width = this.big ? 150 : this.ribbonWidth;
      let left = this.big ? (this.alignRight ? -14 : -35) : this.alignRight ? -5 : this.ribbonLeft;

      let style = `font-size: ${fontSize}px; width: ${width}px; left: ${left}px;`;

      if (this.alignRight) {
        style += `transform: rotate(45deg);`;
      } else {
        style += `transform: rotate(-45deg);`;
      }
      if (this.backgroundColor) {
        style += `background: ${this.backgroundColor} !important;`;
      }
      if (this.fontColor) {
        style += `color: ${this.fontColor} !important;`;
      }
      return style;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.ribbon-wrapper {
  overflow: hidden;
  position: absolute;
  top: -1px;
  .ribbon {
    font-weight: bold;
    text-align: center;
    position: relative;
    padding: 5px 0;
    top: 15px;
    background-color: $color-pink-main;
    color: white;
  }
}
</style>
