<template>
  <div class="share-report-container">
    <b-modal
      ref="share"
      @ok="handleOk"
      @show="handleShow"
      @hidden="handleCancel"
      @keydown.native.enter="handleEnter"
      :size="modalSize(sourceUsers ? sourceUsers.length : 0)"
      lazy
      title="Share this report"
      hide-footer
      centered
    >
      <div>
        <b-form-input
          id="input-email"
          class="mb-2"
          v-model="email"
          :state="emailState"
          type="email"
          placeholder="Email address"
        ></b-form-input>
        <b-form-invalid-feedback id="input-email-feedback" class="mt-0 mb-2">
          Enter a valid email
        </b-form-invalid-feedback>
        <b-form-input
          class="mb-2"
          v-model="firstName"
          type="text"
          placeholder="First name (optional)"
        ></b-form-input>

        <h5>
          <b-badge
            class="emailBadge mr-2 mt-2"
            variant="light"
            @click="populateEmail(user.email, user.forename)"
            v-for="user in sourceUsers"
            v-bind:key="user.email"
            >{{ user.email }}</b-badge
          >
        </h5>

        <div v-if="shared">
          <small class="ml-1">{{ shared }}</small>
        </div>
      </div>

      <div class="modal-footer p-0 pt-2">
        <b-button variant="secondary" @click="handleCancel">Cancel</b-button>
        <b-button v-if="isAdmin" variant="info" @click="handleOk(false)"
          >Send (no follow-ups)</b-button
        >
        <b-button
          variant="primary"
          v-bind:style="{
            'background-color': secondaryColor,
            'border-color': secondaryColor
          }"
          @click="handleOk(true)"
          >Send</b-button
        >
      </div>
    </b-modal>

    <b-modal
      ref="busySharing"
      v-model="busySharing"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="p-3 text-center">
        <div>
          <Busy class="d-inline-block mb-4" primary />
        </div>
        <h4 class="d-inline-block">Sharing report with <br />{{ email }}</h4>
      </div>
    </b-modal>

    <b-modal
      ref="failure"
      id="failure"
      title="Failed to send the email"
      ok-only
      @ok="$refs.failure.hide()"
    >
      <div>
        <p>
          Sorry, we had a problem sending the email to <b>{{ email }}</b
          >. No email has been sent.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
.share-report-container {
  .modalError {
    border-radius: 16px;
    padding: 0.6rem;
  }

  .emailBadge:hover {
    cursor: pointer !important;
  }

  .badgeHighlight {
    background-color: $color-font-para;
    color: white;
  }
  small {
    color: $color-font-para;
  }
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import FormatHelper from "@/helper/formathelper";
import Console from "@/console";
import CognitoAuth from "@/cognito/cognitoauth";
import Busy from "@/components/Busy";

export default {
  name: "ShareSavingsReport",
  props: {
    report: Object,
    primaryColor: {
      type: String,
      default: "#F40C8F"
    },
    secondaryColor: {
      type: String,
      default: "#007bff"
    }
  },
  watch: {
    report: function (value) {
      if (value) {
        this.$refs.share.show();
      }
    }
  },
  components: { Busy },
  data() {
    return {
      isAdmin: false,
      organisation: null,
      firstName: null,
      email: null,
      emailState: null,
      userEmailDomain: null,
      shared: null,
      busySharing: false,
      sourceUsers: [],
    };
  },
  async created() {
    this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
  },
  methods: {
    async loadSourceUsers() {
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}users?includeLinks=true&organisationId=${this.report.organisationId}`
        );

        if (response.data.status === "success") {
          this.sourceUsers = response.data.organisation.sourceUsers;
        } else {
          this.showAlert(
            "Sorry, there was a problem fetching the details for that Organisation.",
            "warning"
          );
        }
      } catch {
        this.showAlert("Sorry, there was a problem fetching the Organisation.", "warning");
      }
    },

    handleShow() {
      this.loadSourceUsers();
      this.firstName = null;
      this.email = null;
      this.emailState = null;
      if (this.report.shared) {
        this.shared = this.sharedOn(this.report.shared);
      }
      CognitoAuth.getCurrentUserEmail().then((email) => {
        this.userEmailDomain = email.split("@")[1];
      });
    },
    handleCancel() {
      this.$emit("cancelled");
      this.$refs.share.hide();
    },
    handleEnter() {
      if (this.enterdEmail()) {
        this.$refs.share.hide();
      }
    },
    handleOk(followUps) {
      if (this.enterdEmail(followUps)) {
        this.$refs.share.hide();
      }
    },
    enterdEmail(followUps) {
      let valid = true;

      if (!this.email) {
        valid = false;
        this.emailState = false;
      } else {
        if (!FormatHelper.validateEmail(this.email)) {
          valid = false;
          this.emailState = false;
        } else {
          this.emailState = null;
          this.handleSend(this.report, followUps);
        }
      }

      return valid;
    },
    populateEmail(emailAddress, firstName) {
      this.email = emailAddress.toLowerCase();
      this.firstName = firstName;
    },
    sharedOn(date) {
      let text = "";

      if (date) {
        text += "First shared on " + FormatHelper.formatDate(date) + ".";
      }

      return text;
    },
    async handleSend(report, followUps) {
      this.busySharing = true;
      try {
        const organisationId = report.organisationId;

        let client = await ApiHelper.http();
        const response = await client.post(
          `${ApiHelper.endPoint()}savings/share?organisationId=${organisationId}&followUp=${followUps}`,
          [
            {
              emailAddress: this.email.toLowerCase(),
              firstName: this.firstName
            }
          ]
        );
        if (response.data.status === "success") {
          EventBus.$emit("show-toast", {
            message:
              "We have sent an email including a link to the savings report to " +
              this.email.toLowerCase(),
            variant: "success"
          });
          this.$emit("sharedSuccessfully", organisationId);
        } else {
          this.$refs.share.hide();
          this.$refs.failure.show();
        }
      } catch (err) {
        Console.error(err);
        this.$refs.share.hide();
        this.$refs.failure.show();
      } finally {
        this.busySharing = false;
      }
    },
    modalSize(size) {
      let modalSize = "md";
      if (size > 45) {
        modalSize = "xl";
      } else if (size > 30) {
        modalSize = "lg";
      }

      return modalSize;
    },
    showAlert(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>
